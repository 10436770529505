<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="finish" />
      </template>

      <template v-slot:title>
        <h2>{{ $t("driverCashWithdraw.title") }}</h2>
        <h1>{{ password }}</h1>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="container infobar">
        <div class="flex-group">
          <div>
            <h2>{{ $t("driverCashWithdraw.date") }}</h2>
            <h3>{{ cashWithdrawal?.date }}</h3>
          </div>
          <div>
            <h2>{{ cashRegisterInfoLabel }}</h2>
            <h3>{{ cashRegisterInfoValue }}</h3>
          </div>
        </div>
      </section>

      <section class="stage" :class="`stage-${stage}`">
        <h2>{{ stageTitle }}</h2>

        <div v-if="stage === STAGES.SELECTION">
          <button
            v-for="(amount, index) in cashWithdrawal?.amounts"
            :key="index"
            type="button"
            class="btn btn-secondary py-1"
            :class="selectedAmount === amount.cents ? 'btn-red' : 'btn-white'"
            @click="() => toggleSelectedAmount(amount.cents)"
          >
            {{ amount.formatted }}
          </button>
        </div>
        <div v-else>
          <h3 class="red">{{ selectedAmountValue }}</h3>
        </div>
      </section>

      <section v-if="stage === STAGES.SUMMARY" class="stage final-state">
        <h2>{{ $t("driverCashWithdraw.new-cash-register-value") }}</h2>
        <h3>{{ cashWithdrawal?.cash_register_value?.formatted }}</h3>
      </section>
    </main>

    <Spinner v-if="loading" />
    <Errors :errors="errors" />

    <footer ref="footer" v-if="cashWithdrawal">
      <div class="container">
        <a
          v-if="stage === STAGES.SUMMARY"
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="printReceipt"
          >{{ $t("prints.general.receipt.reprint") }}</a
        >

        <a
          href="#"
          class="btn btn-primary"
          :class="selectedAmount ? 'btn-green' : 'btn-grey'"
          @click.prevent="confirmButtonAction"
          >{{ confirmButtonText }}</a
        >

        <a
          v-if="stage !== STAGES.SUMMARY"
          href="#"
          class="a-black"
          @click.prevent="cancelButtonAction"
        >
          {{ cancelButtonText }}
        </a>
      </div>
    </footer>

    <OverlayConfirm
      v-if="showOverlayConfirm"
      :title="$t('driverCashWithdraw.cancel-confirm.title')"
      :confirmText="$t('buttonActions.yes')"
      :cancelText="$t('buttonActions.no')"
      @confirm="finish"
      @cancel="showOverlayConfirm = false"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import { formatPrice } from "@/util/format-price";
import Close from "vue-material-design-icons/Close.vue";
import AppHeader from "@/components/AppHeader";
import Errors from "@/components/Errors";
import OverlayConfirm from "@/components/Overlay/Confirm";
import Spinner from "@/components/Spinner";

const STAGES = {
  SELECTION: "selection",
  CONFIRMATION: "confirmation",
  SUMMARY: "summary"
};

export default {
  name: "CashWithdraw",
  mixins: [setActualFooterHeight, getParams],
  components: {
    Close,
    AppHeader,
    Errors,
    OverlayConfirm,
    Spinner
  },
  data() {
    return {
      password: this.$route.params.code,
      selectedAmount: null,
      stage: STAGES.SELECTION,
      showOverlayConfirm: false,
      STAGES
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    this.resetDriverState();
    to.name == "packet-search" && this.resetPacketAndPaymentState();
    next();
  },

  async mounted() {
    if (!this.cashWithdrawal) {
      await this.getCashWithdrawal(this.password);
    }
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("driver", ["cashWithdrawal"]),

    cashRegisterInfoLabel() {
      return this.cashWithdrawal?.previous_register_value
        ? this.$t("driverCashWithdraw.previous-cash-register-value")
        : this.$t("driverCashWithdraw.cash-register-value");
    },

    cashRegisterInfoValue() {
      return (
        this.cashWithdrawal?.previous_cash_register_value?.formatted ??
        this.cashWithdrawal?.cash_register_value?.formatted
      );
    },

    stageTitle() {
      switch (this.stage) {
        case STAGES.SELECTION:
          return this.$t("driverCashWithdraw.select-amount");
        case STAGES.CONFIRMATION:
          return this.$t("driverCashWithdraw.selected-amount");
        default:
          return this.$t("driverCashWithdraw.confirmed-amount");
      }
    },

    selectedAmountValue() {
      return formatPrice(
        this.selectedAmount,
        this.cashWithdrawal?.cash_register_value?.currency
      );
    },

    confirmButtonText() {
      switch (this.stage) {
        case STAGES.SELECTION:
          return this.$t("buttonActions.continue");
        case STAGES.CONFIRMATION:
          return this.$t("driverCashWithdraw.confirm-amount");
        default:
          return this.$t("buttonActions.finish");
      }
    },

    confirmButtonAction() {
      switch (this.stage) {
        case STAGES.SELECTION:
          return this.continueWithdraw;
        case STAGES.CONFIRMATION:
          return this.confirmWithdraw;
        default:
          return this.finish;
      }
    },

    cancelButtonText() {
      return this.stage === STAGES.SELECTION
        ? this.$t("buttonActions.cancel")
        : this.$t("buttonActions.back");
    },

    cancelButtonAction() {
      return this.stage === STAGES.SELECTION
        ? this.openCancelConfirm
        : this.backToSelection;
    }
  },

  methods: {
    ...mapMutations(["clearErrors"]),
    ...mapMutations("driver", ["resetDriverState"]),
    ...mapActions(["resetPacketAndPaymentState"]),
    ...mapActions("driver", [
      "getCashWithdrawal",
      "withdrawCash",
      "printCashWithdrawalReceipt"
    ]),

    toggleSelectedAmount(amount) {
      this.selectedAmount = amount === this.selectedAmount ? null : amount;
    },

    continueWithdraw() {
      if (!this.selectedAmount) return;

      this.stage = STAGES.CONFIRMATION;
    },

    async confirmWithdraw() {
      const response = await this.withdrawCash({
        password: this.password,
        withdrawn_amount_cents: this.selectedAmount
      });
      if (!response) return;

      this.stage = STAGES.SUMMARY;
    },

    async printReceipt() {
      await this.printCashWithdrawalReceipt(this.cashWithdrawal?.id);
    },

    backToSelection() {
      this.stage = STAGES.SELECTION;
    },

    openCancelConfirm() {
      this.showOverlayConfirm = true;
    },

    finish() {
      this.$router.push({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.infobar {
  box-sizing: border-box;
  padding: 9px 16px 12px;
  margin-bottom: 2rem;

  h2 {
    color: $light-grey;
    font-weight: 400;
  }

  h3 {
    font-size: 1rem;
  }

  .flex-group {
    display: flex;
    margin: 8px 0 4px;

    div {
      flex: 1;
    }
  }
}

.stage {
  @extend .container;

  padding: 9px 16px 12px;
  border-top: 1px solid $grey;

  h2 {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: $black-one;
    padding-block: 1rem;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
  }

  button {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &-selection {
    h2 {
      padding-bottom: 2rem;
    }
  }

  &-confirmation,
  &-summary {
    @extend .border-bottom-red, .bg-light-pink;
  }

  &.final-state {
    h2 {
      padding-bottom: 0.5rem;
      color: $black-two;
    }

    h3 {
      font-size: 2rem;
      color: $black-one;
    }
  }
}

footer {
  a {
    margin-bottom: 10px;

    &.a-black:last-child {
      display: inline-block;
      margin: 16px 0 26px;
    }
  }
}
</style>
